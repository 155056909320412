@import 'paper-dashboard/variables';

$bg-active : rgba(33,33,33,1) !default;

// scnf colors palette
$color-orange: #FE5E3A;
$color-yellow: #FFB612;
$color-green: #82BD00;
$color-purple: #A1006A;
$color-blue: #00ADEF;
$color-grey: #CCC;
$color-grey-dark: #212121;

$statusS: #d52b1e;
$statusP: #ffb612;
$statusC: #82be00;
$statusD: #82be00;
$statusB: #0088ce;
$statusR: #ff00aa;
$statusA: #ffff00;

$statusRed: #d52b1e;
$statusOrange: #ffb612;
$statusGreen: #82be00;

$sidebarDetailBg: #FFF;

input, button:focus {
    outline:none;
}

#root {
  overflow: hidden;
}

.main-panel > .content {
  padding:0 30px 0 30px;
  min-height: calc(100vh - 93px);
  margin-top: 93px;
}

/********************************** SIDEBAR ***********************************/
.sidebar-wrapper {
  /*background-image: url(/img/followme.png);*/
  background-repeat: no-repeat;
  background-position: 30px calc(100vh - 260px);
  background-size: 200px 144px;
}

.sidebar:after, .off-canvas-sidebar:after {
  background: linear-gradient(135deg, #0088CE 0%, #A1006B 100%);
}
.sidebar .nav li {
  border-style:solid;
  border-width:0;
  border-bottom-width:1px;
  border-color:$bg-active;
}
.sidebar .nav li > a {
  margin:0;
  padding:20px;
}
.sidebar .nav li.active {
  background-color: $bg-active;

  a {
    color: #FFF !important;
  }
}
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  opacity: 0.5;
}
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after {
  display:none !important;
}
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before {
  display:none !important;
}

.sidebarDetail {
  background-color: $sidebarDetailBg;
  /*padding-bottom: 20px;*/

  .blockSub {
    margin-left: 25px;
    padding:15px;
    padding-left: 0px;
    border:0px solid $color-grey;
    border-bottom-width:1px;

    .btnTag {
      display:flex;
      justify-content: space-between;
      align-items: center;
      width:calc(100% - 10px);
      background: transparent;
      border:1px solid $color-grey;
      border-radius: 8px;
      cursor:pointer;
      margin:2px;
    }

    .selected {
      color:$color-blue;
    }
  }

  .block {
    padding:15px;
    border:0px solid $color-grey;
    border-bottom-width:1px;

    .btnTag {
      display:flex;
      justify-content: space-between;
      align-items: center;
      width:calc(100% - 10px);
      background: transparent;
      border:1px solid $color-grey;
      border-radius: 8px;
      cursor:pointer;
      margin:2px;
    }
    .selected {
      color:$color-blue;
    }
  }
}

.modalRegion {
  .btnTag {
    /*
    background: transparent;
    border:1px solid $color-grey;
    border-radius: 8px;
    cursor:pointer;
    margin:2px;
    */
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:calc(100% - 10px);
    background: transparent;
    border:1px solid $color-grey;
    border-radius: 8px;
    cursor:pointer;
    margin:2px;
  }
  .selected {
    color:$color-blue;
  }
}
/******************************************************************************/

/********************************************************** ITEM **************/
.genderSelect {
  width:200px;
  margin-top:2px;
}

.itemIcon {
  width:32px;
  height:32px;
}

.itemSearchIcon {
  width:72px;
  height:72px;
}

.navbar.navbar-transparent {
  background-color:#FFF !important;
}

.bgOrange { background-color: $color-orange; }
.bgYellow { background-color: $color-yellow; }
.bgGreen { background-color: $color-green; }
.bgPurple { background-color: $color-purple; }

.stateS { background-color: $statusS; }
.stateP { background-color: $statusP; }
.stateC { background-color: $statusC; }
.stateD { background-color: $statusD; }
.stateB { background-color: $statusB; }
.stateR { background-color: $statusR; }
.stateA { background-color: $statusA; }

.statR { background-color: $statusRed }
.statY { background-color: $statusOrange }
.statG { background-color: $statusGreen }

tbody {
    display:block;
    overflow-y:scroll;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
thead {
    width:100%;
}
table {
    width:100%;
}

.aLink {
  color:$color-blue !important;
  text-decoration: underline !important;
  cursor:pointer !important;
}

.form-control {
  color:#000 !important;
}

.btnDeploy {
  background-color: transparent !important;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color:#000 !important;
  font-weight: 400;
  font-size:14px;
  text-transform: none;
  text-align: left;
  padding-left: 10px;
}

.react-select.primary .react-select__control .react-select__value-container--has-value .react-select__single-value {
  color:#000 !important;
}

#selectResarail > div:first-of-type {
  height: 60px !important;
}

.searchResult {
  position: absolute;
  margin-top: 5px;
  margin-left: 145px;
  width: 400px;
  max-height:calc(100vh - 100px);
  overflow: auto;
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 8px;
  box-shadow:0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .row {
    padding:15px;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    border:0 solid #DDD;
    border-bottom-width:1px;

    .resarailName {
      max-width:283px;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
  .row:hover {
    background-color: #EEE;
  }
}

.searchResultBackdrop {
  position:fixed;
  top:0;
  left:0;
  width:260px;
  height:100vh;
  background-color: rgba(0,0,0,0);
}
.searchResultBackdrop2 {
  position:absolute;
  margin-top: 15px;
  margin-left: -31px;
  width:calc(100vw - 260px);
  height:100vh;
  background-color: rgba(0,0,0,0);
}

.error {
  border:1px solid red !important;
}

.rdtPicker th.rdtSwitch {
  width:auto !important;
}

/********************************************************* LOGIN **************/
.mobileBg {
  display:none;
}
.mobileGradient {
  display:none;
}
.loginBg {
  display:flex;
  justify-content:center;
  align-items:center;
  width:100vw;
  height:100vh;
  background-image: url('/loginBg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .loginModal {
    width:800px;
    height:480px;
    background-image: url('/loginModal.png');
    object-fit: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;

    .left {
      display: flex;
      flex:1.37 1;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .panel {
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .right {
      display: flex;
      flex:2;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      input {
        line-height:44px;
        height:44px;
      }
    }
  }
}


/********************************************* MOBILE RESPONSIVE **************/
@media screen and (max-width: 991px){
  .mobileBg {
    position: absolute;
    z-index: 1;
    display:block;
    width:100%;
    height:100%;
    background-image: url('/loginBg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mobileGradient {
    position: absolute;
    z-index: 2;
    display:block;
    width:100%;
    height:100%;
    background: linear-gradient(135deg, rgba(0, 136, 206, 0.7) 0%, #A1006B 100%);
  }
  .loginBg {
    position: absolute;
    z-index: 3;
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    background:transparent;

    .loginModal {
      width:800px;
      height:480px;
      background-image: none;
      object-fit: contain;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;

      .left {
        display: none;
        flex:1.37 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .panel {
          color:white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .right {
        display: flex;
        flex:2;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input {
          line-height:44px;
          height:44px;
        }
      }
    }
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .navbar.navbar-absolute {
    position: fixed;
    width:100vw;
  }
  .main-panel > .content {
    padding:0 10px 0 10px;
    min-height: calc(100vh - 70px);
    margin-top: 70px;
  }

  .searchResult {
    position: absolute;
    margin-top: 5px;
    margin-left: -52px;
    width: 100vw;
    background-color: white;
    border: 1px solid #DDD;
    border-radius: 0px;
    overflow:hidden;
    box-shadow:0 6px 10px -4px rgba(0, 0, 0, 0.15);

    .row {
      padding:15px;
      cursor:pointer;
      display:flex;
      flex-direction:row;
      border:0 solid #DDD;
      border-bottom-width:1px;

      .resarailName {
        max-width:69vw;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
    .row:hover {
      background-color: #EEE;
    }
  }

  .searchResultBackdrop {
    position:fixed;
    top:0;
    left:0;
    width:260px;
    height:100vh;
    background-color: rgba(0,0,0,0);
  }
  .searchResultBackdrop2 {
    position:absolute;
    margin-top: 15px;
    margin-left: -31px;
    width:calc(100vw - 260px);
    height:100vh;
    background-color: rgba(0,0,0,0);
  }

  .listLabel {
    text-transform: uppercase;
    color:$color-blue;
    font-size: 0.7em;
    font-weight: 600;
  }
  .itemIcon {
    width:32px;
    height:32px;
    object-fit: contain;
  }
  .genderSelect {
    width:100%;
    margin-top:2px;
  }
}
